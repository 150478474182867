import { createComponent } from '@ycos/fela'

/**
 * Manages a component's visibility based on its coremedia metadata tags
 */
function displayHelper(tags, vp) {
  const viewportsMap = {
    sl: 'Desktop',
    sm: 'Tablet',
    ssm: 'Smartphone'
  }

  switch (vp) {
    case 'sl':
      return tags.indexOf(viewportsMap.sl) >= 0 || tags.length === 0 ? 'block' : 'none'
    case 'sm':
      return tags.indexOf(viewportsMap.sm) >= 0 || tags.length === 0 ? 'block' : 'none'
    case 'ssm':
      return tags.indexOf(viewportsMap.ssm) >= 0 || tags.length === 0 ? 'block' : 'none'
    default:
      return null
  }
}

const VisibilityWrapper = createComponent(
  'VisibilityWrapper',
  ({ isDeviceSpecific }) => {
    return {
      width: '100%',
      display: 'block',
      'screen-large': {
        display: displayHelper(isDeviceSpecific, 'sl')
      },
      'screen-medium': {
        display: displayHelper(isDeviceSpecific, 'sm')
      },
      'screen-small-max': {
        display: displayHelper(isDeviceSpecific, 'ssm')
      }
    }
  },
  'div'
)

export default VisibilityWrapper
