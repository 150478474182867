import { Analytics } from '@ycos/analytics'

export const trackOnTargetClickEditorial = (componentSiteMerchandisingEntry, gtmAnalytics, brand) => {
  const { contentId, title, destinationUrl, imageUrl, key } = componentSiteMerchandisingEntry
  const analytics = new Analytics({ isLegacy: !gtmAnalytics, brand })
  const isArticleLink = (url) => {
    const articleRegExp = /\/editorial\//i
    return articleRegExp.test(url)
  }

  let eventData

  if (isArticleLink(destinationUrl)) {
    eventData = {
      eventInfo: {
        eventName: 'select_article',
        effect: 'article click'
      },
      category: {
        primaryCategory: 'editorial'
      },
      attributes: {
        articleId: contentId,
        articleName: title
      }
    }
  } else {
    eventData = {
      eventInfo: {
        eventName: 'view_promotion',
        effect: 'promotion click'
      },
      category: {
        primaryCategory: 'promotion'
      },
      attributes: {
        promotionId: contentId,
        promotionName: key,
        locationId: destinationUrl,
        creativeName: imageUrl || null
      }
    }
  }
  analytics.trackEvent(eventData)
  return eventData
}

export default trackOnTargetClickEditorial
