import { Analytics } from '@ycos/analytics'

export const trackPageVisit = (pageName, categoryData, locale, siteMerchandisingItems, publicRuntimeConfig, gender = '', siteMerchandisingListing) => {
  const {
    brand: { brandId: brand },
    dataEnvironment
  } = publicRuntimeConfig
  const headAnalyticsData = getAnalyticsDataLayerObject(pageName, categoryData, siteMerchandisingItems, brand, dataEnvironment, gender)
  const analytics = new Analytics({ isLegacy: false, brand })
  analytics.trackPage(headAnalyticsData, locale, '', siteMerchandisingListing)
}

export const getAnalyticsDataLayerObject = (pageName, categoryData, siteMerchandisingItems = [], brand, dataEnviroment, gender) => {
  return {
    pageInfo: {
      pageName,
      referringURL: document.referrer,
      environment: dataEnviroment,
      ...(gender ? { gender } : {})
    },
    category: categoryData,
    attributes: {
      businessName: brand,
      siteMerchandising: siteMerchandisingItems
    }
  }
}

export default trackPageVisit
