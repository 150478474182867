const getBreadcrumbListLdJson = (router, pageProps, seoConfig, contactDetails) => {
  const { urlKey = null, key } = router?.query
  const { navigationalParents = [], title } = pageProps
  const { hostUrl } = seoConfig

  const itemListElement = navigationalParents
    .map((parent, index) => ({
      ...parent,
      url: index === 0 ? `${ensureSingleTrailingSlash(parent.url)}shop/` : parent.url
    }))
    .concat([{ url: urlKey, title }])
    .map((el, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': ensureSingleTrailingSlash(`${hostUrl}${el.url}`),
        name: el.title
      }
    }))

  const BreadcrumbListJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement
  }

  if (key === 'about-us') {
    return [getCampanyPagesLdJson(router, pageProps, seoConfig, contactDetails), BreadcrumbListJsonLd]
  }

  return BreadcrumbListJsonLd
}

const getCampanyPagesLdJson = (router, pageProps, seoConfig, contactDetails) => {
  const { locale, urlKey } = router?.query
  const { seoHTMLMetaDescription } = pageProps
  const [language, country] = locale?.split('-')

  if (urlKey === `/${locale}/` || urlKey.includes('/mens/')) {
    return
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'THE OUTNET',
    description: seoHTMLMetaDescription,
    url: `https://www.theoutnet.com/${locale}/shop/`,
    logo: 'https://www.theoutnet.com/assets/icons/ton/icon-192x192.png',
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: country,
      availableLanguage: language,
      ...contactDetails
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: '1 The Village Offices, Westfield London, Ariel Way',
      addressLocality: 'London',
      postalCode: 'W12 7GF',
      addressCountry: 'UK'
    },
    sameAs: [
      'https://www.facebook.com/THEOUTNET',
      'https://www.instagram.com/theoutnet/',
      'https://www.youtube.com/user/theoutnet',
      'https://www.linkedin.com/company/the-outnet-com/'
    ]
  }
}

const ensureSingleTrailingSlash = (url) => {
  if (!url) return ''
  return url.endsWith('/') ? url : `${url}/`
}

export { getBreadcrumbListLdJson, getCampanyPagesLdJson }
