import { CUSTOMER_SEGMENTS_TAG, SEGMENTED_EXPERIENCE_VARIANT } from '../components/component-segmented-experience/utils'
import { getTags } from '@ycos/coremedia-renderer'

const extraPropsByLayoutVariant = {
  [SEGMENTED_EXPERIENCE_VARIANT]: (item) => {
    const tags = getTags(item.tags, CUSTOMER_SEGMENTS_TAG)

    return {
      segment: tags?.[0] || ''
    }
  }
}

export default (item, pageKey, parent, additionalCTA = [], isAdditionalCTA = false) => {
  const { type, contentID, layoutVariant, teaserTitle, href, imagePath, analyticsTextString } = item
  const extraProps = extraPropsByLayoutVariant[parent?.layoutVariant]?.(item) || {}

  if (type !== 'ynapteaser') return

  return {
    key: pageKey,
    contentId: contentID,
    componentType: layoutVariant,
    title: teaserTitle,
    destinationUrl: href,
    imageUrl: imagePath,
    position: analyticsTextString || '',
    isAdditionalCTA,
    additionalCTA: Array.isArray(additionalCTA) ? additionalCTA : [additionalCTA],
    segment: '',
    ...extraProps
  }
}
