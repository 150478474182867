import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createComponent } from '@ycos/fela'
import { getProductsListParameters } from '../../server/utils'
import layoutVariant from './layoutVariant'
import ProductCard from './ProductCard'
import VisibilityWrapper from '../component-visibility'
import { getTags } from '@ycos/coremedia-renderer'

const Row = createComponent(
  'Row',
  () => ({
    zIndex: 0,

    flexGrow: 1
  }),
  'div'
)

const PidFeedButtonWrapper = createComponent(
  'PidFeedButtonWrapper',
  () => ({
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0
  }),
  'div'
)

const PidFeedButton = createComponent(
  'PidFeedButton',
  ({ theme }) => ({
    ...theme.homepage.cta,
    marginTop: 0,
    marginBottom: `${theme.spacingMultiplier}px`,

    'screen-small-max': {
      width: '100%',
      textAlign: 'center',
      marginBottom: `${theme.spacingMultiplier * 2}px`
    }
  }),
  'a'
)

const PromoTextContainer = createComponent(
  'PromoTextContainer',
  ({ theme }) => ({
    position: 'absolute',
    width: '100%',
    bottom: `${theme.spacingMultiplier * 2.25}px`,
    marginBottom: `${theme.spacingMultiplier}px`,
    textAlign: 'center'
  }),
  'div'
)

const PromoText = createComponent(
  'PromoText',
  ({ theme }) => {
    return {
      ...theme.typography.styles.Paragraph['4'].bp1,
      color: theme.typography.colors.PrimaryBlack,
      lineHeight: '20px',
      margin: 0
    }
  },
  'p'
)

const LinkWrapper = createComponent('LinkWrapper', () => ({}), 'a')

const LinkContentWrapper = createComponent(
  'LinkContentWrapper',
  () => ({
    'screen-small-max': {}
  }),
  'div'
)

const RowWrapper = createComponent(
  'Row',
  ({ theme }) => ({
    'screen-small-max': {
      minHeight: `${theme.spacingMultiplier * 55}px`,
      boxSizing: 'content-box',
      marginTop: `${theme.spacingMultiplier}px`
    }
  }),
  'div'
)

const PidFeedFragment = createComponent(
  'PidFeedFragment',
  () => ({
    'screen-small-max': {}
  }),
  'div'
)

export const createProductFeedLink = (locale, seoUrl) => {
  if (!locale || !seoUrl) {
    return null
  }

  return `/${locale.id}/shop${seoUrl}`
}

function productHasImage(product) {
  const { imageTemplate, imageViews } = product.productColours[0] || product

  return imageTemplate && imageViews
}

class ProductFeed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      finalProducts: props.products.items,
      productSearchParam: getProductsListParameters(props.item.ynapParameter),
      timer: null
    }
    this.rowContainerRef = React.createRef()
    this.boundStopTimer = this.stopTimer.bind(this)
    this.boundStartTimer = this.startTimer.bind(this)
  }

  componentDidMount() {
    this.rowContainerRef && this.rowContainerRef.current.addEventListener('mouseenter', this.boundStopTimer)
    this.rowContainerRef && this.rowContainerRef.current.addEventListener('mouseleave', this.boundStartTimer)
    this.startTimer()
  }
  componentWillUnmount() {
    this.stopTimer()
    this.rowContainerRef && this.rowContainerRef.current.removeEventListener('mouseenter', this.boundStopTimer)
    this.rowContainerRef && this.rowContainerRef.current.removeEventListener('mouseleave', this.boundStartTimer)
  }

  startTimer = () => {
    this.setState({
      timer: setInterval(() => {
        const { currentIndex, finalProducts = [] } = this.state
        this.setState({ currentIndex: currentIndex >= finalProducts.length - 1 ? 0 : currentIndex + 1 })
      }, 1800)
    })
  }

  stopTimer = () => {
    const { timer } = this.state
    clearInterval(timer)
    this.setState({
      timer: null
    })
  }

  static layoutVariant = layoutVariant

  render() {
    const { locale, item, onTargetClick } = this.props

    const { preTitlePlain, subTitlePlain, tags } = item

    const { finalProducts, currentIndex, productSearchParam } = this.state
    const productListUrl = createProductFeedLink(locale, productSearchParam.seoUrl)
    const isDeviceSpecific = getTags(tags, 'DeviceType')

    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <PidFeedFragment>
          <LinkWrapper href={productListUrl}>
            <LinkContentWrapper>
              <RowWrapper>
                <Row innerRef={this.rowContainerRef} style={{ height: '100%' }}>
                  {finalProducts &&
                    finalProducts
                      .filter((product) => productHasImage(product))
                      .map((product, i) => <ProductCard isVisible={currentIndex === i} product={product} key={i} onTargetClick={onTargetClick} />)}

                  {preTitlePlain && (
                    <PromoTextContainer>
                      <PromoText>{preTitlePlain}</PromoText>
                    </PromoTextContainer>
                  )}
                  {subTitlePlain && (
                    <PidFeedButtonWrapper>
                      <PidFeedButton href={productListUrl} onClick={onTargetClick}>
                        {subTitlePlain}
                      </PidFeedButton>
                    </PidFeedButtonWrapper>
                  )}
                </Row>
              </RowWrapper>
            </LinkContentWrapper>
          </LinkWrapper>
        </PidFeedFragment>
      </VisibilityWrapper>
    )
  }
}

ProductFeed.propTypes = {
  item: PropTypes.shape({
    preTitlePlain: PropTypes.string,
    subTitlePlain: PropTypes.string
  }),
  locale: PropTypes.object,
  products: PropTypes.shape({
    items: PropTypes.array
  }),
  onTargetClick: PropTypes.func
}

ProductFeed.defaultProps = {
  products: {
    items: []
  },
  onTargetClick: () => {}
}

export default ProductFeed
