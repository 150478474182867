import { TONTheme, TONThemeKr, TONThemeRtl, breakpoints } from '@ycos/themes'
import getHomepageStyles from './homepage-styles'
import getEditorialBreakpoints from './editorial-breakpoints'
import tonStyles from './ton.json'

/**
 * Builds the theme with the standard breakpoints.
 */

export function getTheme({ locale = false } = {}) {
  let theme = {
    ...TONTheme,
    typography: tonStyles,
    fullBleed: true,
    direction: 'ltr',
    useCssRTL: false
  }

  switch (locale?.language) {
    case 'ar':
      theme = {
        ...theme,
        ...TONThemeRtl,
        useCssRTL: true,
        fullBleed: true
      }
      break
    case 'ko':
      theme = {
        ...theme,
        ...TONThemeKr
      }
      break
    default:
      break
  }

  theme.breakpoints = getEditorialBreakpoints(breakpoints)
  theme.homepage = getHomepageStyles(theme)

  return theme
}

export function setThemeDirection(locale) {
  const dir = locale.language === 'ar' ? 'rtl' : 'ltr'
  return dir
}
