import qs from 'qs'

const getCampaignEntry = (link) => {
  const queryString = link.substring(link.indexOf('?') + 1)
  const queryParams = qs.parse(queryString)
  const cmspQueryParam = queryParams.cm_sp || ''
  const cmspSections = cmspQueryParam.split('-_-')
  if (cmspSections.length !== 4) {
    return null
  }
  const sectionsAreEmpty = !(cmspSections[0] || cmspSections[1] || cmspSections[2] || cmspSections[3])
  if (sectionsAreEmpty) {
    return null
  }
  return {
    placement: cmspSections[0],
    position: cmspSections[1],
    campaignName: cmspSections[2],
    campaignStartDate: cmspSections[3]
  }
}

export default getCampaignEntry
