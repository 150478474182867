import { Analytics } from '@ycos/analytics'

export const trackVideoProgress = (videoMetadata, gtmAnalytics, brand, status) => {
  const statusCodes = {
    start: {
      eventName: 'video play',
      milestone: 'start'
    },
    end: {
      eventName: 'video complete',
      milestone: 'complete'
    }
  }

  const analytics = new Analytics({
    isLegacy: !gtmAnalytics,
    brand
  })
  const eventData = {
    eventInfo: {
      eventName: statusCodes[status].eventName,
      effect: 'media'
    },
    category: {
      primaryCategory: 'video',
      subCategory: 'editorial'
    },
    attributes: {
      video: {
        videoName: videoMetadata.teaserTitle,
        videoType: 'editorial',
        videoPlayer: 'brightcove',
        milestone: statusCodes[status].milestone
      }
    }
  }

  analytics.trackEvent(eventData)
  return eventData
}

export default trackVideoProgress
