import { getTags, hasTag } from '@ycos/coremedia-renderer'
import { Item }  from '../../../domain/types/CoreMediaItem'

export const CUSTOMER_SEGMENTS_TAG = 'CustomerSegments'

export default (components: Item[] = [], userSegments: string[] = []) : Item => {
    const segmentedComponent = components.find((item) => {
        const tags = getTags(item.tags, CUSTOMER_SEGMENTS_TAG)
        return tags.some((tag) => userSegments.includes(tag))
      })

      const genericComponent = components.find((item) => !hasTag(item.tags, CUSTOMER_SEGMENTS_TAG))

      return segmentedComponent || genericComponent
}
