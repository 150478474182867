import { Analytics } from '@ycos/analytics'

export const trackOnTargetClick = (componentSiteMerchandisingEntry, gtmAnalytics, brand) => {
  const analytics = new Analytics({ isLegacy: !gtmAnalytics, brand })
  const eventData = {
    eventInfo: {
      eventName: 'site merchandising - click',
      effect: 'campaign click'
    },
    category: {
      primaryCategory: 'site merchandising',
      subCategory: componentSiteMerchandisingEntry.key
    },
    attributes: {
      siteMerchandising: [componentSiteMerchandisingEntry]
    }
  }

  analytics.trackEvent(eventData)
  return eventData
}

export default trackOnTargetClick
