export const getProductsListParameters = (str = '') => {
  try {
    return { ...JSON.parse(str), key: str }
  } catch (e) {
    return { key: str }
  }
}

export const getProductApiUrl = ({ params, showMorePageNumber, locale, wcs }) => {
  const brandSite = `theoutnet_${locale.country}`
  const { seoUrl, sort, productsPerPage, offset } = params
  const localeKey = locale ? locale.language : 'en'
  const baseUrl = `${wcs.baseUrl}${wcs.productsByCategoryPath}`
  const pageNumber = showMorePageNumber || parseInt(offset) || 1
  const path = baseUrl
    .replace('{{brandSite}}', brandSite)
    .replace('{{seoUrl}}', seoUrl)
    .replace('{{pageSize}}', productsPerPage || 4)
    .replace('{{pageNumber}}', pageNumber)
    .replace('{{locale}}', localeKey)
  if (sort) {
    return `${path}&orderBy=${sort}`
  }
  return path
}
