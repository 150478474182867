import React from 'react'
import PropTypes from 'prop-types'
import { createComponent } from '@ycos/fela'
import { createFontStyle } from '@ycos/primitives'

const ProductCardContainer = createComponent('ProductCardContainer', () => ({
  position: 'absolute',
  left: 0,
  right: 0,
  transition: 'opacity 800ms ease 0s',
  opacity: 0
}))

const ProductCardLiner = createComponent('ProductCardLiner', ({ theme }) => ({
  background: theme.typography.colors.PrimaryWhite,
  border: theme.custom.productCard.border
}))

const ImageWrapper = createComponent(
  'ImageWrapper',
  () => ({
    'screen-small-max': {
      maxHeight: '330px'
    }
  }),
  'div'
)

const Image = createComponent(
  'Image',
  () => ({
    display: 'block',
    background: '#eee',
    width: '66%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'transparent',

    'screen-small-max': {
      maxHeight: '320px',
      width: 'auto',
      padding: 0
    }
  }),
  'img'
)

const ContentWrapper = createComponent(
  'ContentWrapper',
  () => ({
    width: '100%',
    position: 'relative',
    textAlign: 'center'
  }),
  'div'
)
const ProductInformation = createComponent(
  'ProductInformation',
  ({ theme }) => ({
    marginTop: `${theme.spacingMultiplier * 3}px`,
    maxHeight: `${theme.spacingMultiplier * 4}px`,
    minHeight: `${theme.spacingMultiplier * 3}px`,
    textAlign: 'center',
    width: '100%',
    whiteSpace: 'initial',

    'screen-small-max': {
      marginTop: `${theme.spacingMultiplier * 2}px`
    }
  }),
  'div'
)

const Designer = createFontStyle('Designer', { type: 'Heading', tagName: 'div', name: 'B6' }, ({ theme }) => ({
  color: theme.custom.productCard.nameColor,
  fontSize: '18px',
  fontWeight: 400
}))

export const getProductImageUrl = (product) => {
  const { imageTemplate, imageViews } = product.productColours[0] || product

  return imageTemplate.replace('{view}', imageViews[0]).replace('{width}', '300_q80')
}

class ProductCard extends React.Component {
  state = {
    mouseOver: false
  }

  loadedStyle = {
    opacity: 1
  }

  render() {
    const { product, isVisible, onTargetClick } = this.props
    const { designerName } = product
    const imgUrl = getProductImageUrl(product)

    return (
      <ProductCardContainer data-automation="product" onClick={onTargetClick} style={isVisible && this.loadedStyle}>
        <ProductCardLiner>
          <ImageWrapper data-testid="productcard-image" data-automation="imageWrapper">
            <Image src={imgUrl} />
          </ImageWrapper>
          <ContentWrapper data-automation="content">
            <ProductInformation data-automation="product-information">
              {designerName && (
                <Designer data-testid="productcard-designer" data-automation="designer">
                  {designerName}
                </Designer>
              )}
            </ProductInformation>
          </ContentWrapper>
        </ProductCardLiner>
      </ProductCardContainer>
    )
  }
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    designerName: PropTypes.string,
    productId: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    onSale: PropTypes.bool,
    seo: PropTypes.object
  }),
  isVisible: PropTypes.bool,
  onTargetClick: PropTypes.func
}

ProductCard.defaultProps = {
  products: {
    items: []
  },
  onTargetClick: () => {}
}

export default ProductCard
