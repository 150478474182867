const getEditorialBreakpoints = (breakpoints) => ({
  ...breakpoints,
  small: {
    ...breakpoints.small,
    marginOuter: 14
  },
  medium: {
    ...breakpoints.medium,
    marginOuter: 32,
    width: 768
  }
})

export default getEditorialBreakpoints
